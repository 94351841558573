<template>
  <div :id="`smuc-player--${apikey}`">
    <stream-view
      v-if="stream !== null && isAllowedToBeDisplayed"
      v-show="isVisible"
      :error-message="errorMessage"
      :is-joined="isJoined"
      :language="stream.streamview_language"
      :mode="playerMode"
      :stream="stream"
      :ws-client="wsClient"
      :player-domain="playerDomain"
      :stream-thumbnail-domain="streamThumbnailDomain"
      :optimize-for-ad="optimizeForAd || stream.optimize_for_ad"
      @videoLoaded="() => isReady = true"
      @play="onPlay"
      @pause="onPause"
      @mousedown.native="() => onClickStreamView()"
    />

    <subscribe-notification
      v-if="apikey && showCountdownBox && !expiredCountdownBox"
      :start-date="streamStartDateOriginal"
      :button-color="stream.button_color"
      :text-color="stream.text_color"
      :brand-logo="streamBrandLogo"
      :cover-url="coverUrl"
      :apikey="apikey"
      :language="stream.streamview_language"
      :privacy-policy-url="stream.privacy_policy_url"
      @subscribed="isSubscribedToNotification"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { WsClient, WsClientEvents, HasNoFreeViewerCapacityError } from 'smucio-ws-client';
import browserMixin from '@/mixins/browser';
import { StreamData } from 'smucio-stream-entities'

const mobileBreakdownAt = 920 - 1

const isObj = (o) => Object.prototype.toString.call(o) == '[object Object]'

const _debounce = function _debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args); }, timeout)

    return {
      cancel() {
        clearTimeout(timer)
      }
    }
  };
}

export default {
  name: 'SmucPlayer',
  mixins: [browserMixin],
  data() {
    return {
      time: (new Date()).getTime(),
      wsClient: null,
      playerMode: this.$parent.smucplayermode,
      playerModeFromEmbedCode: null,
      optimizeForAd: this.$parent.optimizeForAd,
      apikey: this.$parent.smucapikey,
      dateNow: new Date(),
      isReady: false,
      stream: null,
      vodChapterNumber: 0,
      isJoined: false,
      errorMessage: null,
      trappedViewport: null,
      isAllowedToBeDisplayed: false,
      subscribedForNotification: false,
      expiredCountdownBox: false,
      pauseStatusTO: null,
      widthTimeout: null,
      archeoIsLoaded: false,
      firstClickReceived: false,
      timingVariationId: null,
    }
  },
  computed: {
    playerDomain() {
      return process.env.VUE_APP_PLAYER_DOMAIN;
    },
    streamThumbnailDomain() {
      return process.env.VUE_APP_STREAM_THUMBNAIL_DOMAIN;
    },
    isVisible() {
      if (this.playerMode === 'embedded' || this.playerMode === 'viewer') {
        return true;
      }

      return this.isReady;
    },
    streamStartDateOriginal() {
      return !isNaN(Date.parse(this.stream.start_date)) ? new Date(Date.parse(this.stream.start_date)) : null
    },
    showCountdownBox() {
      return isObj(this.stream)
        && this.stream.show_countdown_box == true
        && this.playerMode === 'overlay'
        && !this.subscribedForNotification
        && this.streamStartDateOriginal != null
        && this.dateNow < this.streamStartDateOriginal
    },
    windowWidth: {
      get() {
        return this.debouncedWidth;
      },
      set(val) {
        if (this.widthTimeout) {
          clearTimeout(this.widthTimeout)
        }

        this.widthTimeout = setTimeout(() => {
          this.debouncedWidth = val;
        }, 500);
      }
    },
    isMobileBreakpoint() {
      return this.windowWidth <= mobileBreakdownAt
    },
    streamType() {
      switch (parseInt(this.stream.type, 10)) {
        case 0: return 'live'
        case 1: return 'playback'
        case 2: return 'embed'
        default: return 'live'
      }
    },
    liveStatus() {
      if (!this.stream) {
        return 'unknown'
      }

      const status = typeof this.stream.stream_status === 'number' || /^\d+$/.test(this.stream.stream_status) ? parseInt(this.stream.stream_status, 10) : -1
      switch (status) {
        case 0: return 'waiting'
        case 1: return 'live'
        case 2: return 'paused'
        case 3: return 'closed'
        default: return 'unknown'
      }
    },
    isLive() {
      return this.streamType == 'live' && this.liveStatus !== 'closed'
    },
    isPlayback() {
      return this.streamType == 'playback' && this.liveStatus !== 'closed'
    },
    coverLandscape() {
      return isObj(this.stream.cover) && typeof this.stream.cover.url == 'string' ? this.stream.cover : null
    },
    coverPortrait() {
      return isObj(this.stream.cover_portrait) && typeof this.stream.cover_portrait.url == 'string' ? this.stream.cover_portrait : null
    },
    coverUrl() {
      let url;
      if (this.coverPortrait != null && (this.coverOrientation == 'portrait' || this.coverLandscape == null)) {
        url = this.coverPortrait.url;
      }
      else if (this.coverLandscape != null) {
        url = this.coverLandscape.url;
      }

      return typeof url == 'string' && url.length ? url : null;
    },
    coverOrientation() {
      if (
        this.isMobileBreakpoint
        && !this.isMinimized
        && this.theKind !== 'vod'
      ) {
        return 'portrait';
      }

      if (this.theVideoOrientation === 'custom') {
        return this.maxHeight > this.maxWidth ? 'portrait' : 'landscape';
      }

      return this.theVideoOrientation;
    },
  },
  watch: {
    liveStatus(newVal) {
      // @TODO - 'closed' status is nearly impossible to get, but 'paused' can be relevant..
      if (newVal === 'closed' && this.playerMode === 'overlay') {
        this.handleDestroy()
      }
    },
    showCountdownBox: {
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          const diffSeconds = Math.floor(this.stream.start_date - new Date())
          setTimeout(() => this.expiredCountdownBox = true, diffSeconds)
        }
      }
    }
  },
  beforeCreate() {
    this.$root.$bus = new Vue();
  },
  created() {
    console.log(`[SmucPlayer] created :: apikey #${this.apikey} - mode: ${this.playerMode}`)

    if (this.apikey === null) {
      console.error('[SmucPlayer] Missing apikey parameter.')
      return
    }

    this.fetchData();
    this.loadArcheo();

    // this.$root.$bus.$on('streamview.products.toggle.status', this.eventOnProductsToggleStatus);
    // this.$root.$bus.$on('streamview.product.feed.click', this.eventOnProductFeedClicked);
    // this.$root.$bus.$on('streamview.disconnected', this.eventOnStreamviewDisconnected); // @deprecated
    this.$root.$bus.$on('streamview.close', this.eventOnStreamviewClose);
    this.$root.$bus.$on('streamview.player.ended', this.eventOnStreamviewEnded);
    // this.$root.$bus.$on('streamview.chat.status', this.eventOnStreamviewChatStatus);
    // this.$root.$bus.$on('streamview.mute.status', this.eventOnStreamviewMuteStatus);
    this.$root.$bus.$on('streamview.minimize.status', this.eventOnStreamviewMinimizeStatus);
    // this.$root.$bus.$on('streamview.fullscreen.status', this.eventOnStreamviewFullscreenStatus);

    this.trapMetaViewport();

    if (window && window.localStorage) {
      this.subscribedForNotification = localStorage.getItem('smuc_subscribed_for_' + this.apikey) ? true : false
    }
  },
  mounted() {
    if (window && document && this.isIosSafari && this.browserVisualViewportSupported) {
      window.visualViewport.addEventListener('resize', _debounce(this.onResizeIosSafari, 250), { passive: true });
      window.dispatchEvent(new Event('resize'));
    }

    window.addEventListener('resize', this.eventOnResize)
    this.eventOnResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.eventOnResize)

    if (this.wsClient) {
      this.wsClient.disconnect();
    }

    // this.$root.$bus.$off('streamview.products.toggle.status', this.eventOnProductsToggleStatus);
    // this.$root.$bus.$off('streamview.product.feed.click', this.eventOnProductFeedClicked)
    // this.$root.$bus.$off('streamview.disconnected', this.eventOnStreamviewDisconnected); // @deprecated
    this.$root.$bus.$off('streamview.close', this.eventOnStreamviewClose);
    this.$root.$bus.$off('streamview.player.ended', this.eventOnStreamviewEnded);
    // this.$root.$bus.$off('streamview.chat.status', this.eventOnStreamviewChatStatus);
    // this.$root.$bus.$off('streamview.mute.status', this.eventOnStreamviewMuteStatus);
    this.$root.$bus.$off('streamview.minimize.status', this.eventOnStreamviewMinimizeStatus);
    // this.$root.$bus.$off('streamview.fullscreen.status', this.eventOnStreamviewFullscreenStatus);

    if (window && document && this.isIosSafari && this.browserVisualViewportSupported) {
      window.visualViewport.removeEventListener('resize', this.onResizeIosSafari, { passive: true });
    }

    // Reset parent site's original meta viewport value if available..
    if (this.trappedViewport !== null) {
      const $viewport = document.querySelector('meta[name="viewport"]')
      if ($viewport) {
        $viewport.setAttribute('content', this.trappedViewport)
      }
    }
  },
  methods: {
    fetchData() {
      let smcUrl = `https://${process.env.VUE_APP_PLAYER_DOMAIN}/s/${this.apikey}`;
      /* let smcUrl = `${process.env.VUE_APP_SMUC_STREAMS_URL}${this.apikey}.smc?${(new Date()).getTime()}`;
      if (
        [
          '1409520761c96765ac36ffbec07bf348af28f065',  // EZ HIÁNZIK.

          // DB-ből jött lista
          '52e57cdc3089abf716389fbe8bc5adbad1b1cee1',
          '7ebeb24abc7d0b73ddb4f465f4e7932b82cc8490',
          'c59d2a275060b05cd76cde6e1ffc4c724a349e0f',
          'dc0a1e157abb09de09d6c8d9538b48e136d9204e',
          'ad1c4dbf5db8b66d37c1e96ee15212704299a0ca',
          '027188c1929f364042709d0d84804a5dfc8644f9',
          'ad5b9e58c3b4b3166317734b808c21ca95091718',
          'de4a9f4501b7f63d6ff5544c81013f1f0ad90e96',
          '27d58b2de87cb815532c69c5b845d611abbb1f20',
          '1a0902212d010135d9f061a9ecac65a38b31fb9f',
          'fcf24f4f7ca8fcd081a07087b5922ff8d4d0ba61',
          '79a901e6a260a18117cc495e4b703f6f144e4a7e',
          '7e121d812b7ad55d05ba87de8998e70012ff5abd',
          '972b3a62aa0f512733b1d31c0051a90269ce4186',
          '6ca297247d4fd398adbedf5721551467179594a1',
          'a5e80214dd2b63a7ead60ced65b76fe21ec8afa0',
          'b2f81727e9bede0dedbb5a7ea2a0fd1e765fad29',
          'ef853615881e6ac033e307d115e788fbdc1f6fca',
          '432fd0fb3e2b3791b5fae5784137e2ad95a868f3',
          '0b38fe376f3d8c29069dd7e34353e46e3c1daad1',
          '0abee01119d0b220918291e10aba1e7a0c216d53',
          'd1bbf248cb7b40f83b250ea451763fb298ad79b4',
          'a8576b39634021b4b4bb245bc446b199cfdbab5b',
          '6eb6e791586b2fe5cc7df29314394a3ceb0c33aa',
          '1d1c386ff2e1b34aed8183f75e22d641d5a1292d',
          '6456ac4e0d1260b1587845f7e4f01aa71b776037',
          '1bf6e013c0736ab3bea1cec336c6d7c09b43f35d',
          '91985369446f2602309e475811605bbc76fdec0f',
          '54bc2641e57d6536d4114a78935e126a43118e97',
          '4fb5ea7bf0ed5aa11adc7512488062db7c9b3e41',
          'f7f64bfac1d54583d8e4970e164a5cd40cfe8e77',
          '45cef9811af2dff830acba324cbd08900bb896a3',
          '9286e8b7dc47a4d67d2a31b6da0c2fbae4bcf68f',
          '9e956ad4b1a0ba1b5778e629f64f15224cfbac8d',
          'f4ee6bc666b7645139d9df9ffb31fef4ad0b94b7', // 2
          '5213b2a4ca8c67f18983e0262bf25b25199d7b48',
          '2cfb671933091bf6df46ce483a4db1fe5db8e712',
          '7244c43644b2e13a8997a6829977889af4cfe7d2',
          '15726c1e875bc3f4722a8d10372038b009677b45', // 2
          '105b5a8c728acf281764c754ff8e7ffde84c86e6',
          '2c211ee6ff69b044d7e5a2a89ff2076dc0e1def1', // 2
          '7424dab1d51e1c45bc5fb791ffbb9ea6b479090b', // 2
          '3ebd7e7b4787484b101c474e91ca967454947efb', // 2
          '7f159dd443a5abc5437661af9d325aaede25f575',
          'df33f146ca31864bf136197ffc9a76a619863a88',
          'e53f45cb03722c11e6245979e2b7631c7eafc9df',
          'ae1ea232e731b1581187da7255965e1bf2347897',
          'd1d86fbcd19155854b807e80834773661409bcb7',
          '9d1c5406912424dafed2230a18ec8b6658ea2644',
          '5624ee7034bfd3bec61fb0767654e462afebee1c',
          'c46983aca9b222abf336bbf6c40f1892659817b5',
          'ffa3c5294e4e460fcc653171888de4bee5f00d37',
          'b5037a117e7ab02f434946c8664c3c25a29f84bb',
          '3138d2bbc3c225cccbc92a6ae7232e60c63eca3f',
          '94aa28e0fe95d9eb7637d0d8d7914f832cd76802',
          '488665aba514d96065cc1c4b9ab3f51229919f17',
          '8644fb437d07a3f2686eecc48fc504a9969ce684',
          'f23bda4b2494561fbfb495a260425e6b639a3f1e', // 2
          '5358fe431c6234f59b1e8da8c2b10510a36831ed', // 2
          '0abba8ad7e92f3172894c25158f354526dec4d85', // 2
          'd17fe23745b28974a5ada4461f0b68a9cb21db65', // 2
          '13a3fb6f5e3d17982bf0b40343a26deb9b99ca16', // 2
          'c29ff624c9d77c227123e2f65c1a37be7f91704b',
          '00d4d6dd48f6b5fa4570e2e28933e605acebc965', // 2
          'a19e0c34f81f8c8c20b44814bf74f7479197767b', // 2
          'cd40b3586a9a15660ee793af92c572073a2faf2f',
          'f03e14016df1d10d61b47166e5337c0217e308d8',
          '125d622f7ec7a9984954a6ce29ce2b0e195327e7',
          '3bf87a13cd154e53763b1c4976e1dbdfd61df620',
          '52cb9801d105405ffa23616737a0b1d77fd14daf',
          '5b7ac34da8842bef78bf4e0bfcefa2d507616e9a',
          'a3095d5c9b50034c6155c1aafc7774b7ea0bd78d', // 2
          '4016d26372a196d5a6e4124e059e8745e2a48af6', // 2
          '6d1baf0f9b562a3963dee4564458a73cdb22519a',
          '44593ceb1ba6278d0cd41da8c6c873f367194334',
          '971cccfe63e69d54b229045e34c4618246dd6168',
          '3c068fcd64705262ed0fde7c605047c42af124f8',
          'a3c790cd5e556e7b04f367101a008b75b937ee17',
          '108a9e1d30ecd5e2f992d931a60dc93729c88ebb',
          '0d87cafcecb6b0aaada98ef674cacf5b064ea0d5', // 2
          'ffc2662c0f572fb614d3ef5c71cfa561e299db50',
          '1b2964c6cc3e1218c0f5ce9546337cb11fc3b9fe',
        ].includes(this.apikey)
      ) {
        smcUrl = `https://static.marquardmedia.hu/${this.apikey}.smc?${(new Date()).getTime()}`;
      } */

      window.fetch(smcUrl)
        .then(response => response.json())
        .then(data => {
          const streamData = new StreamData(data)
          streamData.apikey = this.apikey
          if (streamData.optimize_for_ad) {
            streamData.ad_size = typeof streamData.ad_size == 'string' && streamData.ad_size.includes('x') ? streamData.ad_size : '300x600'
          } else if (this.optimizeForAd && typeof streamData.ad_size !== 'string') {
            streamData.optimize_for_ad = true
            streamData.ad_size = '300x600'
          }

          // Backward-compatibility
          if (typeof data.rounded_corners == 'undefined') {
            streamData.rounded_corners = 1;
          }

          // H4ck: Change "Subscription" form label to "Enquire".
          const enquireHackedPlayers = [
            '787b32ab6359af623eb525bd49dee717386bc37e',
            '7a87bff4cc984bcdf27ff456861a6ed8a381bcb9',
            'f40e3e936c0510848e6b001698dcbc7c98c1ac42',
            '4d7cb948694e6d757140ccf98efaa1f6a20377ee',
          ];
          if (enquireHackedPlayers.includes(streamData.apikey)) {
            streamData.tab_subscription_form_label = 'Enquire';
          }

          this.stream = streamData
          console.log('[SMUC] Player', this.stream)

          if (this.stream.auto_card_timing) {
            // this.loadAutoCardTiming();
          }

          this.$nextTick(() => {
            this.initWsConnection();
          });

          this.customizeViewerBackground(data.ad_bg_url)

          // @TODO: Should we check to isVOD status here?!
          if (this.liveStatus === 'closed') {
            this.isAllowedToBeDisplayed = true
            return;
          }

          this.processStreamData()
        })
        .catch(e => {
          console.error('[SmucPlayer] Failed to retrieve Stream data.', e)
        })
    },
    processStreamData() {
      if (!this.checkFrequencyOfOccurrence()) {
        console.info('[SmucPlayer] Player cannot be displayed due to frequency of occurrence of the stream.')
        return
      }

      this.isAllowedToBeDisplayed = true

      this.updateFrequencyOfOccurrence()
    },
    checkFrequencyOfOccurrence() {
      if (this.playerMode !== 'overlay') {
        return true
      }

      const foo = this.stream.frequency || 'always'
      const lskey = `smuc_frequency_opened_at_${this.apikey}`
      const freq = window.localStorage && window.localStorage.getItem(lskey) !== null ? window.localStorage.getItem(lskey) : null
      // Display player when no localstorage record was found
      // OR stored value is not the 13 digits long value of Date.getTime()
      // OR we forced to display always.
      if (
        freq === null
        || /^\d{13}$/.exec(freq) === null
        || foo === 'always'
      ) {
        return true
      }

      const freqDate = new Date(parseInt(freq, 10))

      if (foo === 'close') {
        // Higher freqDate is possible due to updateFrequencyOfOccurrence(true)
        // in eventOnStreamviewClose() method. This is the case when
        // user clicks on the [X] button.
        return freqDate < new Date()
      }

      let dateOffset = 0
      if (foo === 'hourly') {
        dateOffset = 1 * 3600 * 1000
      } else if (foo === 'daily') {
        dateOffset = 24 * 3600 * 1000
      } else if (foo === 'weekly') {
        dateOffset = 7 * 24 * 3600 * 1000
      }
      const dateToReopen = new Date(freqDate.getTime())
      dateToReopen.setTime(dateToReopen.getTime() + dateOffset)

      if (new Date() >= dateToReopen) {
        return true
      }

      return false
    },
    updateFrequencyOfOccurrence(closePermanently = false) {
      if (!window.localStorage) {
        return
      }

      const toDateTime = closePermanently ? (new Date('2087-03-01 06:00:00')).getTime() : (new Date()).getTime()
      const lskey = `smuc_frequency_opened_at_${this.apikey}`
      window.localStorage.setItem(lskey, toDateTime)
    },
    initWsConnection() {
      this.wsClient = new WsClient({
        wsUrl: process.env.VUE_APP_CHAT_WS_URL,
        apiKey: this.apikey,
        serverPublicKey: process.env.VUE_APP_WS_MESSAGE_SERVER_PUBLIC_KEY,
        clientPrivateKey: process.env.VUE_APP_WS_MESSAGE_CLIENT_PRIVATE_KEY,
      });
      this.wsClient.addEventListener(WsClientEvents.ERROR, (err) => {
        if (err instanceof HasNoFreeViewerCapacityError) {
          this.errorMessage = 'You cannot join at this time because the broadcast has reached its maximum audience.';
        }
      });
      this.wsClient.addEventListener(WsClientEvents.JOINED, () => {
        this.isJoined = true;
      });
      this.wsClient.addEventListener(WsClientEvents.STREAM_STATUS_CHANGED, (status) => {
        this.stream.stream_status = status;

        if (this.liveStatus !== 'live') {
          this.wsClient.pause();
        }
      });
    },
    destroy() {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    },
    onPlay() {
      if (this.wsClient) {
        this.wsClient.play();
        this.wsClient.getInfo();
      }
    },
    onPause() {
      if (this.wsClient) {
        this.wsClient.pause();
      }
    },
    // eventOnStreamviewDisconnected() {
    //   console.log('Streamview Disconnected!')
    //   this.handleDestroy()
    // },
    // eventOnProductFeedClicked(e) {
    //   console.log('Product Clicked!', e)
    // },
    eventOnStreamviewClose() {
      console.log('Streamview Close!')
      if (this.stream.frequency === 'close') {
        this.updateFrequencyOfOccurrence(true)
      }

      this.destroy()
    },
    // eventOnStreamviewChatStatus(e) {
    //   console.log('Streamview Chat!', e)
    // },
    // eventOnStreamviewMuteStatus(e) {
    //   console.log('Streamview Mute!', e)
    // },
    eventOnStreamviewMinimizeStatus(e) {
      console.log('Streamview Minimize!', e)
      this.isMinimized = e
    },
    // eventOnStreamviewFullscreenStatus(e) {
    //   console.log('Streamview Fullscreen!', e)
    // },
    // eventOnProductsToggleStatus(e) {
    //   console.log('Streamview Toggle!', e)
    // },
    eventOnStreamviewEnded() {
      console.log('Streamview Ended!')
      this.handleDestroy()
    },
    handleDestroy() {
      if (this.isLive) {
        if (this.playerMode === 'viewer') {
          const $msg = document.createElement('div')
          $msg.classList.add('livestream-has-ended')
          $msg.innerHTML = 'The live stream has been over.<br>Thank you for your participation!'
          document.body.appendChild($msg)
        }


        if (this.playerMode === 'overlay') {
          this.destroy()
        }

        return
      }

      if (this.isPlayback) {
        if (this.playerMode === 'overlay') {
          this.destroy()
        }

        return
      }
    },
    onResizeIosSafari() {
      if (this.playerMode !== 'viewer') {
        return
      }

      const measuredHeight = document.documentElement ? document.documentElement.clientHeight : window.innerHeight;
      document.querySelector('html').style.height = measuredHeight + 'px';
      document.body.style.height = measuredHeight + 'px';
      window.scrollTo({
        top: 1,
        left: 0
      })
    },
    trapMetaViewport() {
      if (this.playerMode === 'viewer') {
        return
      }

      const $viewport = document.querySelector('meta[name="viewport"]')
      if ($viewport === null) {
        const $meta = document.createElement('meta')
        $meta.setAttribute('name', 'viewport')
        $meta.setAttribute('content', 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,shrink-to-fit=no')
        document.head.appendChild($meta)
        return
      }

      let content = $viewport.getAttribute('content')
      this.trappedViewport = content
      if (content.includes('maximum-scale')) {
        content = content.replace(/(maximum-scale=([0-9](\.?[0-9])?),?)/, 'maximum-scale=1.0,').replace(/,$/, '')
        $viewport.setAttribute('content', content)
      } else {
        $viewport.setAttribute('content', content + ',maximum-scale=1.0')
      }
    },
    loadArcheo() {
      if (this.archeoIsLoaded) {
        return;
      }

      this.archeoIsLoaded = true;

      let archeo = { app: process.env.VUE_APP_ARCHEO_APP, queue: [] };
      this.$root.$bus.$on('aq', function (args) { archeo.queue.push(args) });

      setTimeout(() => {
        if (!this.errorMessage) {
          const script = document.createElement('script');
          script.src = 'https://archeo.bannerse.com/a.js?vn=smcajs';
          script.defer = true;
          script.onload = () => {
            archeo = window.smcajs(archeo);
            archeo.pageview({ content_id: this.apikey });
          }
          document.head.appendChild(script);
        }
      }, 1000);
    },
    checkCloudflareM3u8File(url) {
      return new Promise(resolve => {
        if (!url) {
          url = this.theVideoSource;
        }
        if (this.theVideoSource.indexOf('.m3u8') === -1) {
          resolve();
          return;
        }

        console.log('Checking Cloudflare m3u8 file: ' + url);

        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = async () => {
          if (xhr.readyState === 4) {
            if (xhr.responseText.trim().length === 0) {
              await this.checkCloudflareM3u8File(url);
            }

            if (url === this.theVideoSource) {
              let m3u8Lines = xhr.responseText.split('\n').filter(line => line[0] !== '#').map(line => line.trim());
              if (m3u8Lines.length === 0) {
                await this.checkCloudflareM3u8File(url);
              }

              for (let i = 0; i < m3u8Lines.length; ++i) {
                let nextUrl = this.theVideoSource.split('/');
                nextUrl.pop();
                nextUrl = nextUrl.join('/') + '/' + m3u8Lines[i];

                await this.checkCloudflareM3u8File(nextUrl);
              }
            }

            resolve();
          }
        }
        xhr.open('GET', url, true);
        xhr.send();
      });
    },
    isSubscribedToNotification() {
      if (window && window.localStorage && !location.href.includes('bannerse.com')) {
        localStorage.setItem('smuc_subscribed_for_' + this.apikey, 1)
        this.subscribedForNotification = true
      }
    },
    eventOnResize() {
      this.windowWidth = window.innerWidth
    },
    customizeViewerBackground(adBackgroundUrl) {
      const $universe = document.querySelector('#smuc-universe');

      if (
        !(
          this.playerMode === 'viewer'
          && typeof adBackgroundUrl == 'string'
          && /^https?/.exec(adBackgroundUrl) != null
          && this.stream.optimize_for_ad
        )
      ) {
        if ($universe) {
          $universe.classList.add('smuc-universe')
          $universe.classList.add('fade-in')
        }
        return
      }

      let css = '.smuc-universe {background:url("' + adBackgroundUrl + '") no-repeat 50% 50%;background-size:cover;}';
      css += '.smuc-universe::before {display:none}';
      const style = document.createElement('style');

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      document.getElementsByTagName('head')[0].appendChild(style);

      $universe.classList.add('smuc-universe')
      $universe.classList.add('fade-in')
    },
    loadAutoCardTiming() {
      window.fetch(`${process.env.VUE_APP_SMUC_AUTO_TIMING_SERVICE}/timing-variation/${this.apikey}`)
        .then(response => response.json())
        .then(data => {
          const convertSecToTime = (s) => {
            const d = new Date(0);
            d.setSeconds(s);

            return d.toISOString().substring(11, 19);
          };
          data.payload.timing.forEach(timing => {
            this.stream.feed.forEach(feedItem => {
              if (feedItem.id == timing.id) {
                feedItem.pinned_from = convertSecToTime(timing.from);
                feedItem.pinned_to = convertSecToTime(timing.to);
              }
            });
          });

          this.timingVariationId = data.payload.id;
        });
    },
    onClickStreamView() {
      /* if (this.timingVariationId) {
        let url = `${process.env.VUE_APP_SMUC_AUTO_TIMING_SERVICE}/timing-variation/${this.apikey}/${this.timingVariationId}/click`;
        if (!this.firstClickReceived) {
          url += '?first=1';
          this.firstClickReceived = true;
        }

        window.fetch(url, { method: 'POST' });
      } */
    },
  },
}
</script>
